import * as d3 from "d3";

export const unitPin = ({
  strokeWidth = 5,
  nameFontSize = 18,
  fontSize = 11,
  name,
  price,
  colours: { border, background, textName, textPrice },
  classList,
  size = 100,
  scaleOnHover,
}) => {
  const scale = size / 100;
  const scaledStrokeWidth = strokeWidth * scale;
  const scaledNameFontSize = nameFontSize * scale;
  const scaledFontSize = fontSize * scale;
  const lineHeight = scaledFontSize * 1.1;
  const diameter = 74 * scale;
  const radius = diameter / 2;

  // We need to format this number because in the case of an estate
  // the current unit filtering system concats the unit address and
  // the unit name (with a pipe delimiter) and uses that as the adhoc
  // unit name. This formatting reverts that unit name change
  let nameFormatted;
  if (name !== undefined) {
    if (!name.includes(" | ")) {
      nameFormatted = name;
    } else {
      nameFormatted = name.split(" | ")[1];
    }
  }

  const pinSvg = d3
    .create("svg")
    .attr("width", diameter)
    .attr("height", diameter)
    .style("overflow", "visible");

  const pinGroup = pinSvg
    .append("g")
    .attr("transform-origin", `${radius}px ${radius}px`);

  pinGroup
    .append("circle")
    .attr("cx", radius)
    .attr("cy", radius)
    .attr("r", radius - scaledStrokeWidth)
    .attr("stroke-width", scaledStrokeWidth)
    .attr("stroke", border)
    .attr("fill", background);

  const textGroup = pinGroup
    .append("g")
    .attr("transform", `translate(${radius}, ${radius})`);

  if (nameFormatted !== undefined) {
    textGroup
      .append("text")
      .attr("text-anchor", "middle")
      .attr("font-family", "Montserrat, Helvetica, Arial, sans-serif")
      .style("font-size", `${scaledNameFontSize}px`)
      .style("font-weight", "800")
      .attr("fill", textName)
      .text(nameFormatted);
  }

  if (price !== undefined) {
    textGroup
      .append("text")
      .attr("y", lineHeight)
      .attr("text-anchor", "middle")
      .attr("font-family", "Montserrat, Helvetica, Arial, sans-serif")
      .style("font-size", `${scaledFontSize}px`)
      .style("font-weight", "600")
      .attr("fill", textPrice)
      .text(price);
  }

  if (classList !== undefined) {
    pinSvg.attr("class", classList);
  }

  if (scaleOnHover !== undefined && scaleOnHover !== 1) {
    let transitionDuration = 100;

    pinSvg.on("mouseover", () => {
      pinSvg.classed("!opacity-100", true);
      pinSvg.raise();

      pinGroup
        .transition()
        .duration(transitionDuration)
        .ease(d3.easeCubicInOut)
        .attr("transform", `scale(${scaleOnHover})`);
    });

    pinSvg.on("mouseout", () => {
      pinSvg.classed("!opacity-100", false);

      pinGroup
        .transition()
        .duration(transitionDuration)
        .ease(d3.easeCubicInOut)
        .attr("transform", `scale(1)`);
    });
  }

  return pinSvg.node();
};
