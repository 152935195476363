<template>
  <div id="main-unit-reserve-list" class="min-h-screen">
    <!-- Filter -->
    <div
      v-if="
        this.currentListComponent !== 'Shortlist' ||
        this.currentListComponentEstate !== 'ShortlistEstate'
      "
      class="flex flex-col lg:flex-row py-4 px-2 bg-white top-0 z-30 shadow-lg"
      :class="showOnlineUsers ? 'is-users-online' : 'filter-sticky'"
    >
      <!-- Non hidden Filter all screens -->
      <div class="flex flex-row centerContainer lg:w-32">
        <div class="w-1/2 lg:w-full px-1">
          <div class="flex flex-row">
            <input
              class="w-full px-2 pb-1 placeholder-gray relative bg-transparent text-sm border-0 border-b-2 border-surface-200 focus:ring-0 focus:border-surface"
              type="text"
              :placeholder="unitOrPlotOrEstateName"
              v-model="searchFilter"
              @keydown.enter="filterName(searchFilter)"
            />
            <SearchIcon
              class="mt-2 cursor-pointer transform hover:scale-110"
              @click="filterName(searchFilter)"
            />
          </div>
        </div>

        <div class="w-1/2 lg:hidden px-1">
          <button
            @click="displayFilters = !displayFilters"
            class="relative flex flex-row px-4 py-2 items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-surface-300 hover:text-surface-900 focus:text-surface-900 hover:bg-surface-200 focus:bg-surface-200 focus:outline-none focus:shadow-outline"
          >
            Filters

            <svg
              fill="currentColor"
              viewBox="0 0 20 20"
              :class="{
                'rotate-180': displayFilters,
                'rotate-0': !displayFilters,
              }"
              class="absolute top-0 right-2 w-5 h-full transition-transform duration-200 transform"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <!-- Hidden mobile filter options -->
      <div
        class="flex-grow mt-5 lg:mt-0"
        :class="{ 'hidden lg:flex': !displayFilters }"
      >
        <div
          v-if="this.units.length && globals[0] && globals[0].layoutStyle"
          class="filterContainer"
        >
          <div
            v-if="globals[0].isPriceFilter"
            class="filter-container-price lg:order-1"
            :class="filterContainerClass"
            id="v-step-filter-price"
          >
            <PriceFilterMinMax
              :selectedMinPrice="minPrice"
              :selectedMaxPrice="maxPrice"
              @update:min-price-filter="filterPrice($event)"
              @update:max-price-filter="filterPrice($event)"
              title="Price"
            />
          </div>

          <div
            v-if="globals[0].isUnitTypes"
            class="filter-container lg:order-2"
            :class="filterContainerClass"
          >
            <UnitFilter
              :propUnits="unitTypes"
              @update:unit-filter="filterUnitType($event)"
            />
          </div>

          <div
            v-if="globals[0].isAspectFilter"
            class="filter-container"
            :class="`${filterContainerClass}
              ${
                globals[0].layoutStyle[0] === 'estate'
                  ? 'lg:order-4'
                  : 'lg:order-3'
              }`"
          >
            <AspectFilter
              :propAspects="aspects"
              @update:aspect-filter="filterAspect($event)"
            />
          </div>

          <div
            v-if="globals[0].isOutlookFilter"
            class="filter-container"
            :class="`
            ${filterContainerClass}
              ${
                globals[0].layoutStyle[0] === 'estate'
                  ? 'lg:order-3'
                  : 'lg:order-4'
              }`"
          >
            <ViewFilter
              :propViews="views"
              @update:view-filter="filterView($event)"
            />
          </div>

          <div
            v-if="
              globals[0].layoutStyle[0] === 'estate' &&
              globals[0].isFloorsFilter
            "
            class="filter-container lg:order-5"
            :class="filterContainerClass"
          >
            <LayoutFilter
              :propLayouts="layouts"
              :isOutlook="globals[0].isOutlookFilter"
              @update:layout-filter="filterLayout($event)"
            />
          </div>

          <div
            v-else-if="
              globals[0].layoutStyle[0] !== 'estate' &&
              globals[0].isFloorsFilter
            "
            class="filter-container lg:order-5"
            :class="filterContainerClass"
          >
            <FloorFilter
              :propFloors="floors"
              :isOutlook="globals[0].isOutlookFilter"
              @update:floor-filter="filterFloor($event)"
            />
          </div>

          <div
            class="filter-container lg:order-6"
            :class="filterContainerClass"
          >
            <SortFilter
              :propOrderBy="orderBy"
              :isOutlook="globals[0].isOutlookFilter"
              @update:order-by-filter="filterOrderBy($event)"
            />
          </div>

          <div
            class="filter-container lg:order-7"
            :class="filterContainerClass"
          >
            <StatusFilter
              :propStatus="status"
              :isOutlook="globals[0].isOutlookFilter"
              @update:status-filter="filterStatus($event)"
            />
          </div>

          <div
            class="filter-container lg:order-8"
            :class="filterContainerClass"
          >
            <Share :isOutlook="globals[0].isOutlookFilter" />
          </div>
          <div
            class="filter-container lg:order-10 reset-filters"
            :class="filterContainerClass"
          >
            <button
              :disabled="clearButtonDisabled"
              @click="resetFilters"
              class="w-full px-1 py-2 text-sm font-semibold focus:outline-none focus:border-none disabled:opacity-50 transform hover:scale-110"
              style="color: #dc0002"
            >
              <CloseCircleOutlineIcon :size="24" class="inline-block" />
              <span class="inline-block ml-1 relative">Reset Filters</span>
            </button>
          </div>

          <div
            class="filter-container lg:order-9"
            :class="filterContainerClass"
          >
            <div
              @click="displayFilters = false"
              class="w-full displayAmount font-semibold text-center text-white bg-green-500 border border-green-500 rounded"
              :class="totalUnits > 99 ? 'text-xs' : 'text-sm'"
            >
              <span class="font-bold">
                <span v-if="totalUnits > 0">
                  <span class="lg:hidden">Show</span> {{ totalUnits
                  }}<span class="mobi mobiMargin">/</span>
                </span>
                <span class="mobi mobiMargin">{{ units.length }}</span>
              </span>
              <span class="mobi mobiMargin">{{
                upperCaseUnitOrPlotOrEstate
              }}</span>
              <span class="mobiShow mobiMargin"> Results</span>
            </div>
          </div>

          <div
            class="mobiShow filter-container lg:order-11"
            :class="filterContainerClass"
          >
            <div
              @click="displayFilters = !displayFilters"
              class="bg-primary hover:bg-primary-600 w-full p-2 font-semibold text-center text-sm rounded lg:hidden border text-white"
            >
              <span class="font-bold">
                <span> Done </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Layout Select -->
    <div
      v-if="
        this.currentListComponent === 'Shortlist' ||
        this.currentListComponentEstate === 'ShortlistEstate'
      "
      class="has-tooltip"
    >
      <div
        class="border-0 relative flex flex-col w-full outline-none focus:outline-none mt-5 justify-center"
      >
        <p
          class="font-semibold text-3xl border-0 relative flex flex-col w-full outline-none focus:outline-none mx-auto justify-center text-center uppercase"
        >
          My Shortlist
        </p>
      </div>
    </div>

    <div
      class="h-10 flex flex-row justify-center my-7"
      v-if="globals[0]?.layoutStyle"
    >
      <template
        v-if="
          this.currentListComponent !== 'Shortlist' ||
          this.currentListComponentEstate !== 'ShortlistEstate'
        "
      >
        <button
          v-on:click="setCardLoadingTrue()"
          class="flex items-center mr-2 px-4 py-2 text-sm font-semibold uppercase rounded-lg hover:bg-primary-400 hover:text-white focus:outline-none"
          :class="
            globals[0].layoutStyle[0] === 'estate'
              ? currentListComponentEstate == 'CardsEstate'
                ? 'bg-primary text-white'
                : 'bg-white text-surface-900'
              : currentListComponent == 'Cards'
                ? 'bg-primary text-white'
                : 'bg-white text-surface-900'
          "
        >
          <span class="mr-2">Card</span>
          <i class="pi pi-home" style="font-size: 1rem"></i>
        </button>
        <button
          v-if="planEnabled"
          class="flex items-center mr-2 px-4 py-2 text-sm font-semibold uppercase rounded-lg hover:bg-primary-400 hover:text-white focus:outline-none"
          :class="
            globals[0].layoutStyle[0] === 'estate'
              ? currentListComponentEstate == 'PlansEstate'
                ? 'bg-primary text-white'
                : 'bg-white text-surface-900'
              : currentListComponent == 'Plans'
                ? 'bg-primary text-white'
                : 'bg-white text-surface-900'
          "
          @click="swopUnitToPlan()"
        >
          <span class="mr-2">Plan</span>
          <i class="pi pi-map-marker" style="font-size: 1rem"></i>
        </button>
        <button
          v-on:click="setListLoadingTrue()"
          class="flex items-center px-4 py-2 text-sm font-semibold uppercase rounded-lg hover:bg-primary-400 hover:text-white focus:outline-none"
          :class="
            globals[0].layoutStyle[0] === 'estate'
              ? currentListComponentEstate == 'TableEstate'
                ? 'bg-primary text-white'
                : 'bg-white text-surface-900'
              : currentListComponent == 'TableUnit'
                ? 'bg-primary text-white'
                : 'bg-white text-surface-900'
          "
        >
          <span class="mr-2">List</span>
          <i class="pi pi-list" style="font-size: 1rem"></i>
        </button>
      </template>

      <div
        v-if="
          this.currentListComponent === 'Shortlist' ||
          this.currentListComponentEstate === 'ShortlistEstate'
        "
        class="has-tooltip w-full flex flex-col items-center"
      >
        <button
          v-on:click="setBackToPriceListLoadingTrue()"
          style="max-width: 220px"
          class="flex flex-row p-2 text-white uppercase tracking-wide bg-surface rounded centerContainer"
        >
          <div class="iconContainer">
            <BackButtonIcon class="mr-1" />
          </div>
          <span class="mr-1">Back To Price List</span>
        </button>
      </div>
    </div>

    <div
      v-if="
        componentLoading ||
        shortlistLoading ||
        listLoading ||
        cardsLoading ||
        planLoading
      "
    >
      <UnitsLoading />
    </div>

    <template v-if="globals[0]?.layoutStyle">
      <component
        v-if="
          currentListComponentEstate !== 'PlansEstate' &&
          currentListComponent !== 'Plans'
        "
        :is="
          this.globals[0].layoutStyle[0] === 'estate'
            ? currentListComponentEstate
            : currentListComponent
        "
        :units="filteredUnits"
        :settings="globals[0]"
        :global="globals[0]"
        :status="status"
        :total="totalUnitsWithoutStatus"
        :available-only="availableOnly"
        :guaranteed-rentals="isGuaranteedRentals"
        :sort-field="
          this.globals[0].isStandNo
            ? orderByDBSettingStandNoFirst
            : orderByDBSetting
        "
        :sort-direction="orderByReverse ? 'desc' : 'asc'"
        :msTillLaunchTime="msTillLaunchTime"
        @update-status="filterStatus"
        @update-sorting="updateSorting"
        @update:unit-dialog-data="updateUnitDialogData($event)"
        @update:reserve-dialog-data="updateReserveDialogData($event)"
        @update:tooltip="updateTooltip($event)"
      />
      <PlansView
        v-else
        :units="units"
        :filteredUnitsIds="filteredUnits.map(({ id }) => id)"
        @clickPin="clickPin"
      />
    </template>

    <div
      class="w-full mt-12 text-xs pb-12 text-center"
      v-if="!filteredUnits.length > 0 && !componentLoading"
    >
      <p class="font-semibold">
        No {{ upperCaseUnitOrPlotOrEstate }} available
      </p>
      <p class="text-sm">Please check your filter settings</p>
      <button
        class="bg-primary hover:bg-primary-600 mt-3 px-3 py-2 text-white rounded-xl transform hover:scale-110"
        @click="resetFilters"
      >
        Reset Filters
      </button>
    </div>

    <div
      v-if="
        globals[0] &&
        globals[0].layoutStyle &&
        globals[0].layoutStyle[0] === 'estate'
      "
    >
      <UnitDialogEstate
        :msTillLaunchTime="msTillLaunchTime"
        :unitDialogData="unitDialogData"
        @update:unitDialogData="
          (newUnitDialogData) => (unitDialogData = newUnitDialogData)
        "
        :settings="globals[0]"
        @update:tooltip="updateTooltip($event)"
      />
    </div>
    <div v-else>
      <UnitDialog
        :msTillLaunchTime="msTillLaunchTime"
        :unitDialogData="unitDialogData"
        @update:unitDialogData="
          (newUnitDialogData) => (unitDialogData = newUnitDialogData)
        "
        :settings="globals[0]"
        @update:tooltip="updateTooltip($event)"
      />
    </div>

    <div
      v-if="
        globals[0].displaySplashScreen &&
        !hideSplashScreen &&
        !componentLoading &&
        user.profile &&
        !user.profile.hideSplashScreen
      "
      class="fixed bottom-10 right-10 text-white z-50 px-6 bg-surface borderRadius"
    >
      <div class="relative max-w-5xl bg-surface">
        <button
          @click="setSplashSessionStorage()"
          class="bg-primary hover:bg-primary-600 z-50 absolute -top-5 -right-4 px-2 text-white text-4xl leading-none font-semibold border-0 rounded-full outline-none focus:outline-none"
        >
          ×
        </button>
        <div id="container">
          <div
            id="block"
            class="rounded bg-surface"
            @click="setSplashSessionStorage()"
          ></div>
          <div class="output bg-surface">
            <BaseEditorConverter
              id="text"
              class="bg-surface"
              :settings="globals[0]"
              :value="globals[0].splashScreenMessage"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center items-center">
      <button
        class="loadMoreButton lb-admin-primary-button-large mt-5 margin-bottom:15px"
        v-if="this.totalUnits > units.length"
        @click="
          getNextUnits(
            this.globals[0].isStandNo
              ? orderByDBSettingStandNoFirst
              : orderByDBSetting,
            orderByReverse,
          )
        "
      >
        Load more
      </button>
    </div>

    <div
      v-if="
        isPendingBlocked &&
        globals[0].isModalNoAvailableUnits &&
        (!user ||
          !user.profile ||
          user.profile.emailNoAvailableUnits === undefined ||
          user.profile.emailNoAvailableUnits === null ||
          user.profile.emailNoAvailableUnits === false)
      "
    >
      <ModalNoAvailableUnits />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { profilesCollection, settingsCollection } from "@/firebaseCompat.js";
import { conforms } from "lodash-es";
import { filter } from "lodash-es";
import { includes } from "lodash-es";
import { indexOf } from "lodash-es";
import { isEmpty } from "lodash-es";
import { toInteger } from "lodash-es";
import { isNil } from "lodash-es";
import { reverse } from "lodash-es";
import { mixin as clickaway } from "vue3-click-away";
import UnitsLoading from "../MainUnitReserveListUnitsLoading.vue";
import AspectFilter from "../MainUnitReserveListFilterAspect.vue";
import ViewFilter from "../MainUnitReserveListFilterView.vue";
import FloorFilter from "../MainUnitReserveListFilterFloor.vue";
import LayoutFilter from "../MainUnitReserveListFilterLayout.vue";
import SortFilter from "../MainUnitReserveListFilterSort.vue";
import StatusFilter from "../MainUnitReserveListFilterStatus.vue";
import UnitFilter from "../MainUnitReserveListFilterUnitType.vue";
import PriceFilterMinMax from "../MainUnitReserveListFilterPriceMinMax.vue";
import ShareLink from "../MainUnitReserveListFilterShareLink.vue";
import Share from "../MainUnitReserveListShare.vue";
import CardButtonIcon from "vue-material-design-icons/Cellphone.vue";
import SearchIcon from "vue-material-design-icons/Magnify.vue";
import TableButtonIcon from "vue-material-design-icons/Laptop.vue";
import Shortlist from "../MainUnitReserveListShortlist.vue";
import ShortlistEstate from "../MainUnitReserveListShortlistEstate.vue";
import Cards from "../MainUnitReserveListCards.vue";
import CardsEstate from "../MainUnitReserveListCardsEstate.vue";
import PlansView from "./PlansView.vue";
import TableUnit from "../MainUnitReserveListTable.vue";
import TableEstate from "../MainUnitReserveListTableEstate.vue";
import UnitDialog from "../UnitDialog.vue";
import UnitDialogEstate from "../UnitDialogEstate.vue";
import LinkIcon from "vue-material-design-icons/LinkVariant.vue";
import EmailIcon from "vue-material-design-icons/EmailOutline.vue";
import CloseCircleOutlineIcon from "vue-material-design-icons/CloseCircleOutline.vue";
import { unitsCollection } from "@/firebaseCompat.js";
import ShortlistIcon from "vue-material-design-icons/Star.vue";
import BackButtonIcon from "vue-material-design-icons/ArrowLeftCircleOutline.vue";
import ModalNoAvailableUnits from "../ModalNoAvailableUnits.vue";
import BaseEditorConverter from "../../shared/BaseEditorConverter.vue";
import * as Sentry from "@sentry/vue";

export default {
  name: "MainMenuReserveList",
  props: {
    msTillLaunchTime: {
      type: Number,
    },
  },
  components: {
    BaseEditorConverter,
    AspectFilter,
    ViewFilter,
    FloorFilter,
    LayoutFilter,
    PriceFilterMinMax,
    ShareLink,
    SortFilter,
    StatusFilter,
    UnitFilter,
    CardButtonIcon,
    Shortlist,
    ShortlistEstate,
    Cards,
    CardsEstate,
    PlansView,
    SearchIcon,
    TableUnit,
    TableEstate,
    TableButtonIcon,
    UnitDialog,
    UnitDialogEstate,
    UnitsLoading,
    LinkIcon,
    EmailIcon,
    CloseCircleOutlineIcon,
    Share,
    ShortlistIcon,
    BackButtonIcon,
    ModalNoAvailableUnits,
  },
  mixins: [clickaway],
  data: () => ({
    stopListeningSettings: undefined,
    stopListeningUnits: undefined,
    stopListeningSettingsPlan: undefined,
    planEnabled: false,
    availableOnlyValue: true,
    now: new Date(),
    searchFilter: "",
    minPrice: 0,
    maxPrice: 99000000,
    aspects: [],
    views: [],
    floors: [],
    layouts: [],
    unitTypes: [],
    orderBy: "default",
    orderByReverse: false,
    orderByDBSetting: "name",
    orderByDBSettingStandNoFirst: "standNumber",
    status: "all",
    displayAvailableOnly: false,
    displayFilters: false,
    clearButtonDisabled: false,
    unitsPopUp: [],
    units: [],
    filteredUnits: [],
    filteredUnitsWithoutStatus: [],
    filters: {},
    hideSplashScreen: false,
    currentListComponent: "Cards",
    currentListComponentEstate: "CardsEstate",
    dropdownPopoverShow: false,
    componentLoading: false,
    shortlistLoading: false,
    listLoading: false,
    cardsLoading: false,
    planLoading: false,
    unitDialogData: {
      open: false,
      id: "",
    },
    reserveDialogData: {
      open: false,
      id: "",
    },
    limit: 18,
    startAt: 0,
    lastVisible: 0,
    roleStatuses: ["admin", "admin-viewonly", "superadmin"],
  }),
  methods: {
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    updateSorting(sortCriteria) {
      this.orderByDBSetting = sortCriteria.field;
      this.orderByDBSettingStandNoFirst = sortCriteria.field;
      this.orderByReverse = sortCriteria.direction === "desc";
      this.getUnits(
        this.globals[0].isStandNo
          ? this.orderByDBSettingStandNoFirst
          : this.orderByDBSetting,
        this.orderByReverse,
      );
    },
    setSplashSessionStorage() {
      profilesCollection
        .doc(this.user.data.uid)
        .update({
          hideSplashScreen: true,
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
      sessionStorage.setItem("hideSplashScreen", "true");
      this.hideSplashScreen = true;
    },
    getSplashSessionStorage() {
      const session = sessionStorage.getItem("hideSplashScreen");
      this.hideSplashScreen = !!session;
    },
    setBackToPriceListLoadingTrue() {
      this.cardsLoading = true;
      this.resetFilters();
      setTimeout(() => {
        this.swopUnitToCards();
      }, 200);
    },
    setListLoadingTrue() {
      this.listLoading = true;
      setTimeout(() => {
        this.swopUnitToList();
      }, 200);
    },
    setCardLoadingTrue() {
      this.cardsLoading = true;
      setTimeout(() => {
        this.swopUnitToCards();
      }, 200);
    },
    swopUnitToList() {
      this.currentListComponent = "TableUnit";
      this.currentListComponentEstate = "TableEstate";
      this.listLoading = false;
    },
    swopUnitToCards() {
      this.currentListComponent = "Cards";
      this.currentListComponentEstate = "CardsEstate";
      this.cardsLoading = false;
    },
    swopUnitToPlan() {
      this.planLoading = true;
      setTimeout(() => {
        this.currentListComponent = "Plans";
        this.currentListComponentEstate = "PlansEstate";
        this.planLoading = false;
      }, 200);
    },
    clickPin({ unit }) {
      const data = this.units.find(({ id }) => id === unit);
      if (data === undefined) return;

      this.updateUnitDialogData({ open: true, data });
    },
    updateUnitDialogData(e) {
      if (e.open) {
        const el = document.body;
        el.style.overflow = "hidden";
      } else {
        const el = document.body;
        el.style.overflow = "auto";
      }
      this.unitDialogData = {
        open: e.open,
        data: e.data,
      };
    },
    // === Filter ===
    removeFilter(property) {
      delete this.filters[property];
    },
    resetFilters() {
      this.filters = {};
      this.searchFilter = null;
      this.minPrice = 0;
      this.maxPrice = 99000000;
      this.aspects = [];
      this.views = [];
      this.floors = [];
      this.layouts = [];
      this.unitTypes = [];
      this.orderBy = "default";
      this.status = "all";
      this.removeFilter("isGuaranteedRental");
      this.filterOrderBy("default");

      this.$router.push({ query: {} });

      this.applyFilters();
    },
    applyFilters() {
      this.filteredUnits = filter(this.units, conforms(this.filters));
      let clone = Object.assign({}, this.filters);
      delete clone.status;
      delete clone.isGuaranteedRental;
      this.filteredUnitsWithoutStatus = filter(this.units, conforms(clone));
    },
    filterName(value) {
      Object.assign(this.$route.query, { search: encodeURI(value) });
      this.filters.name = (val) => includes(val, value);
      this.searchFilter = value;
      this.applyFilters();
    },
    filterPrice(e) {
      const selectedPrice = toInteger(e.price);

      if (
        e.code === "minprice" &&
        selectedPrice > 0 &&
        this.minPrice !== null
      ) {
        this.minPrice = selectedPrice;
      }

      if (
        e.code === "maxprice" &&
        selectedPrice > 0 &&
        selectedPrice < 99000000
      ) {
        this.maxPrice = selectedPrice;
      }

      if (this.user.profile && this.user.profile.answeredDiscountSurvey) {
        this.filters.price = (unitPrice) => {
          let globalDiscountPrice = unitPrice;
          if (this.globals[0].globalDiscountType === "amount") {
            const discountAmount = this.globals[0].globalDiscountAmount;
            globalDiscountPrice = unitPrice - discountAmount;
          }

          if (this.globals[0].globalDiscountType === "percentage") {
            const discountPercentage = this.globals[0].globalDiscountAmount;
            globalDiscountPrice =
              unitPrice * ((100 - discountPercentage) / 100);
          }

          return (
            toInteger(globalDiscountPrice) > toInteger(this.minPrice + 1) &&
            toInteger(globalDiscountPrice) < toInteger(this.maxPrice + 1)
          );
        };
      } else {
        this.filters.price = (val) => {
          return (
            toInteger(val) > toInteger(this.minPrice + 1) &&
            toInteger(val) < toInteger(this.maxPrice + 1)
          );
        };
      }

      this.applyFilters();
    },
    filterView(e) {
      // TODO: Update to be dynamic with multiple values e.g. aspect or view/direction
      let selectedViews = e;

      if (typeof selectedViews === "string") {
        this.views = selectedViews.split(",");
      } else {
        this.views = selectedViews;
      }

      if (isEmpty(selectedViews) && !isNil(this.filters.view)) {
        this.removeFilter("view");
      } else {
        this.filters.view = (val) => indexOf(this.views, val) >= 0;
      }

      this.applyFilters();
    },
    filterAspect(e) {
      // TODO: Update to be dynamic with multiple values e.g. aspect or view/direction
      var selectedAspects = e;

      if (typeof selectedAspects === "string") {
        this.aspects = selectedAspects.split(" and ");
      } else {
        this.aspects = selectedAspects;
      }

      if (isEmpty(selectedAspects) && !isNil(this.filters.aspect)) {
        this.removeFilter("aspect");
      } else {
        this.filters.aspect = (val) => indexOf(this.aspects, val) >= 0;
      }
      this.applyFilters();
    },
    filterLayout(e) {
      var selectedLayouts = e;

      if (typeof selectedLayouts === "string") {
        this.layouts = selectedLayouts.split(",");
      } else {
        this.layouts = selectedLayouts;
      }

      if (isEmpty(selectedLayouts) && !isNil(this.filters.layout)) {
        this.removeFilter("layout");
      } else {
        this.filters.layout = (val) => indexOf(this.layouts, val) >= 0;
      }

      this.applyFilters();
    },
    filterFloor(e) {
      var selectedFloors = e;

      if (typeof selectedFloors === "string") {
        this.floors = selectedFloors.split(",");
      } else {
        this.floors = selectedFloors;
      }

      if (isEmpty(selectedFloors) && !isNil(this.filters.floor)) {
        this.removeFilter("floor");
      } else {
        this.filters.floor = (val) => indexOf(this.floors, val) >= 0;
      }

      this.applyFilters();
    },
    filterUnitType(e) {
      var selectedUnitType = e;

      if (typeof selectedUnitType === "string") {
        this.unitTypes = selectedUnitType.split(",");
      } else {
        this.unitTypes = selectedUnitType;
      }

      if (isEmpty(selectedUnitType) && !isNil(this.filters.unitType)) {
        this.removeFilter("unitType");
      } else {
        this.filters.unitType = (val) => indexOf(this.unitTypes, val) >= 0;
      }

      this.applyFilters();
    },
    filterOrderBy(e) {
      if (e === "default") {
        this.orderBy = "default";
        this.orderByReverse = false;
        this.orderByDBSetting = "name";
        this.orderByDBSettingStandNoFirst = "standNumber";
        this.globals[0].isStandNo
          ? this.units.sort((a, b) => (a.standNumber > b.standNumber ? 1 : -1))
          : this.units.sort((a, b) => (a.price > b.price ? 1 : -1));
      }

      if (e === "name") {
        this.orderBy = "name";
        this.orderByReverse = false;
        this.orderByDBSetting = "name";
        this.orderByDBSettingStandNoFirst = "standNumber";
        this.units.sort((a, b) => (a.price > b.price ? 1 : -1));
      }

      if (e === "low-to-high") {
        this.orderBy = "low-to-high";
        this.orderByDBSetting = "price";
        this.orderByDBSettingStandNoFirst = "price";
        this.orderByReverse = false;
        this.units.sort((a, b) => (a.price > b.price ? 1 : -1));
      }

      if (e === "high-to-low") {
        this.orderBy = "high-to-low";
        this.orderByDBSetting = "price";
        this.orderByDBSettingStandNoFirst = "price";
        this.orderByReverse = true;
        this.units.sort((a, b) => (a.price < b.price ? 1 : -1));
      }
      this.applyFilters();
    },
    filterStatus(e) {
      if (e === "all") {
        this.status = "all";
        this.displayAvailableOnly = false;
      }
      if (e === "available-only") {
        this.status = "available-only";
        this.displayAvailableOnly = true;
      }
      if (e === "available-and-pending") {
        this.status = "available-and-pending";
        this.displayAvailableOnly = true;
      }
      if (e === "gr-only") {
        this.status = "gr-only";
        this.displayAvailableOnly = true;
      }
      if (e === "my-shortlist") {
        this.status = "my-shortlist";
        this.displayAvailableOnly = true;
      }
      if (e === "popularity") {
        this.status = "popularity";
        this.displayAvailableOnly = true;
      }
      if (this.status === "all") {
        this.removeFilter("status");
        this.removeFilter("isGuaranteedRental");
        this.removeFilter("shortlistIncrement");
        this.removeFilter("prearranged");
      } else if (this.status === "available-only") {
        this.filters.status = (val) => includes(val, "available");

        this.removeFilter("isGuaranteedRental");
        this.removeFilter("shortlistIncrement");
      } else if (this.status === "available-and-pending") {
        this.filters.status = (val) =>
          includes(val, "available") || includes(val, "pending");

        this.removeFilter("isGuaranteedRental");
        this.removeFilter("shortlistIncrement");
      } else if (this.status === "my-shortlist") {
        this.currentListComponent = "Shortlist";
        this.currentListComponentEstate = "ShortlistEstate";
        this.shortlistLoading = false;
        this.removeFilter("status");
        this.removeFilter("isGuaranteedRental");
        this.removeFilter("shortlistIncrement");
      } else if (this.status === "popularity") {
        this.filters.shortlistIncrement = (val) => val > 0;

        this.orderByDBSetting = "shortlistIncrement";
        this.orderByDBSettingStandNoFirst = "shortlistIncrement";
        this.orderByReverse = true;

        this.units.sort((a, b) =>
          a.shortlistIncrement > b.shortlistIncrement ? -1 : 1,
        );
        this.filteredUnits.sort((a, b) =>
          a.shortlistIncrement > b.shortlistIncrement ? -1 : 1,
        );

        this.removeFilter("status");
        this.removeFilter("isGuaranteedRental");
      } else {
        this.filters.isGuaranteedRental = (val) => {
          return val === true;
        };
        this.filters.status = (val) =>
          includes(val, "available") || includes(val, "pending");
        this.orderByDBSetting = "name";
        this.orderByDBSettingStandNoFirst = "standNumber";
        this.orderByReverse = true;
        this.removeFilter("shortlistIncrement");
      }
      this.applyFilters();
    },
    getUnits(orderByReverse) {
      const toggleLoading = this.units.length === 0;
      if (toggleLoading) {
        this.componentLoading = true;
        this.shortlistLoading = true;
        this.listLoading = true;
        this.cardsLoading = true;
        this.planLoading = true;
      }

      if (this.stopListeningUnits) {
        this.stopListeningUnits();
      }
      this.stopListeningUnits = unitsCollection
        .where("displayOnHomePage", "==", true)
        .onSnapshot((snap) => {
          let units = [];

          snap.forEach((doc) => {
            const data = doc.data();
            let unit = {
              id: doc.id,
              unitType: data.type,
              shortlistIncrement: data.shortlistIncrement || 0,
            };

            if (this.globals[0].layoutStyle[0] === "estate") {
              unit = {
                ...data,
                ...unit,
                name: data.address + (data.name ? ` | ${data.name}` : ""),
                floor: data.description,
                internalArea: data.homeSize,
                externalArea: data.standSize,
                totalArea: data.flatSizeApartment,
                parking: data.parkingBays,
                garage: data.garage,
                aspect: data.beds,
                view: data.storeys,
                layout: data.phase,
              };
            } else {
              unit = {
                ...data,
                ...unit,
                name: data.name,
                floor: data.floor,
                internalArea: data.internalArea,
                externalArea: data.externalArea,
                totalArea: data.totalArea,
                parking: data.parking,
                garage: 0,
                aspect: data.aspect,
                view: data.view,
                layout: data.layout,
              };
            }

            units.push(unit);
          });

          if (orderByReverse) {
            this.units = reverse(units);
          } else {
            this.units = units;
          }

          this.applyFilters();

          if (!toggleLoading) return;

          this.componentLoading = false;
          this.shortlistLoading = false;
          this.listLoading = false;
          this.cardsLoading = false;
          this.planLoading = false;
        });
    },
    updateNow() {
      this.now = Date.now();
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      globals: "globals",
      onlineUsers: "onlineUsers",
      isPendingBlocked: "isPendingBlocked",
    }),
    usersOnline: function () {
      return this.onlineUsers.usersActive + this.onlineUsers.usersIdle;
    },
    showOnlineUsers: function () {
      return (
        this.globals[0].isUsersOnline &&
        this.usersOnline > (this.globals[0].onlineUserThreshold || 0)
      );
    },
    filterContainerClass() {
      const classList = ["filter-container"];
      if (this.globals[0].isOutlookFilter) {
        if (this.globals[0].isAspectFilter) {
          if (this.globals[0].isPriceFilter) {
            if (this.globals[0].isUnitTypes) {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width");
              } else {
                classList.push("filter-container-width-one-column-short");
              }
            } else {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-one-column-short");
              } else {
                classList.push("filter-container-width-two-columns-short");
              }
            }
          } else {
            if (this.globals[0].isUnitTypes) {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-one-column-short");
              } else {
                classList.push("filter-container-width-two-columns-short");
              }
            } else {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-two-columns-short");
              } else {
                classList.push("filter-container-width-three-columns-short");
              }
            }
          }
        } else {
          if (this.globals[0].isPriceFilter) {
            if (this.globals[0].isUnitTypes) {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-one-column-short");
              } else {
                classList.push("filter-container-width-two-columns-short");
              }
            } else {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-two-columns-short");
              } else {
                classList.push("filter-container-width-three-columns-short");
              }
            }
          } else {
            if (this.globals[0].isUnitTypes) {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-two-columns-short");
              } else {
                classList.push("filter-container-width-three-columns-short");
              }
            } else {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-three-columns-short");
              } else {
                classList.push("filter-container-width-four-columns-short");
              }
            }
          }
        }
      } else {
        if (this.globals[0].isAspectFilter) {
          if (this.globals[0].isPriceFilter) {
            if (this.globals[0].isUnitTypes) {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-one-column-short");
              } else {
                classList.push("filter-container-width-two-columns-short");
              }
            } else {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-two-columns-short");
              } else {
                classList.push("filter-container-width-three-columns-short");
              }
            }
          } else {
            if (this.globals[0].isUnitTypes) {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-two-columns-short");
              } else {
                classList.push("filter-container-width-three-columns-short");
              }
            } else {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-three-columns-short");
              } else {
                classList.push("filter-container-width-four-columns-short");
              }
            }
          }
        } else {
          if (this.globals[0].isPriceFilter) {
            if (this.globals[0].isUnitTypes) {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-two-columns-short");
              } else {
                classList.push("filter-container-width-three-columns-short");
              }
            } else {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-three-columns-short");
              } else {
                classList.push("filter-container-width-four-columns-short");
              }
            }
          } else {
            if (this.globals[0].isUnitTypes) {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-three-columns-short");
              } else {
                classList.push("filter-container-width-four-columns-short");
              }
            } else {
              if (this.globals[0].isFloorsFilter) {
                classList.push("filter-container-width-four-columns-short");
              } else {
                classList.push("filter-container-width-five-columns-short");
              }
            }
          }
        }
      }
      return classList.join(" ");
    },
    upperCaseUnitOrPlotOrEstate: function () {
      if (!this.globals[0] || !this.globals[0].layoutStyle[0]) {
        return "Units";
      }
      if (
        (this.globals[0].layoutStyle[0] === "floor" ||
          this.globals[0].layoutStyle[1] === "floor" ||
          this.globals[0].layoutStyle[2] === "floor") &&
        (this.globals[0].layoutStyle[0] === "plot size" ||
          this.globals[0].layoutStyle[1] === "plot size" ||
          this.globals[0].layoutStyle[2] === "plot size") &&
        (this.globals[0].layoutStyle[0] === "estate" ||
          this.globals[0].layoutStyle[1] === "estate" ||
          this.globals[0].layoutStyle[2] === "estate")
      ) {
        return "Units / Plots / Homes";
      } else if (
        this.globals[0].layoutStyle[0] === "floor" ||
        this.globals[0].layoutStyle[1] === "floor" ||
        this.globals[0].layoutStyle[2] === "floor"
      ) {
        return "Units";
      } else if (
        this.globals[0].layoutStyle[0] === "plot size" ||
        this.globals[0].layoutStyle[1] === "plot size" ||
        this.globals[0].layoutStyle[2] === "plot size"
      ) {
        return "Plots";
      } else if (
        this.globals[0].layoutStyle[0] === "estate" ||
        this.globals[0].layoutStyle[1] === "estate" ||
        this.globals[0].layoutStyle[2] === "estate"
      ) {
        return "Homes";
      }
      return "Units";
    },
    unitOrPlotOrEstateName: function () {
      if (!this.globals[0] || !this.globals[0].layoutStyle[0]) {
        return "Unit No.";
      }
      if (
        (this.globals[0].layoutStyle[0] === "floor" ||
          this.globals[0].layoutStyle[1] === "floor" ||
          this.globals[0].layoutStyle[2] === "floor") &&
        (this.globals[0].layoutStyle[0] === "plot size" ||
          this.globals[0].layoutStyle[1] === "plot size" ||
          this.globals[0].layoutStyle[2] === "plot size") &&
        (this.globals[0].layoutStyle[0] === "estate" ||
          this.globals[0].layoutStyle[1] === "estate" ||
          this.globals[0].layoutStyle[2] === "estate")
      ) {
        return "Unit No. / Plot No. / Estate Address";
      } else if (
        this.globals[0].layoutStyle[0] === "floor" ||
        this.globals[0].layoutStyle[1] === "floor" ||
        this.globals[0].layoutStyle[2] === "floor"
      ) {
        return "Unit No.";
      } else if (
        this.globals[0].layoutStyle[0] === "plot size" ||
        this.globals[0].layoutStyle[1] === "plot size" ||
        this.globals[0].layoutStyle[2] === "plot size"
      ) {
        return "Plot No.";
      } else if (
        this.globals[0].layoutStyle[0] === "estate" ||
        this.globals[0].layoutStyle[1] === "estate" ||
        this.globals[0].layoutStyle[2] === "estate"
      ) {
        return "Unit No.";
      }
      return "Unit No.";
    },
    disableReserve: function () {
      if (
        this.globals[0] &&
        this.globals[0].launchDate &&
        this.globals[0].launchDate.toDate().getTime() + 2000 >
          this.msTillLaunchTime
      ) {
        if (this.units.isOneDayOnlyDiscount === true) {
          return false;
        }
        let userProfileRole =
          this.user.profile === null ? "user" : this.user.profile.role;
        if (
          this.roleStatuses.includes(userProfileRole) &&
          this.globals[0].bypassAdmin === true
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    totalUnits: function () {
      if (
        this.currentListComponent == "Shortlist" ||
        this.currentListComponentEstate == "ShortlistEstate"
      ) {
        return this.user.profile.unitShortlist.length;
      } else if (
        this.filteredUnitsWithoutStatus !== null &&
        this.status === "available-only"
      ) {
        return this.filteredUnitsWithoutStatus.filter(
          (item) => item.status === "available",
        ).length;
      } else if (
        this.filteredUnitsWithoutStatus !== null &&
        this.status === "available-and-pending"
      ) {
        return this.filteredUnitsWithoutStatus.filter(
          (item) => item.status === "available" || item.status === "pending",
        ).length;
      } else if (this.filteredUnitsWithoutStatus !== null) {
        return this.filteredUnitsWithoutStatus.length;
      } else if (this.filteredUnits !== null) {
        return this.filteredUnits.length;
      }
      return 0;
    },
    totalUnitsWithoutStatus() {
      if (this.filteredUnitsWithoutStatus !== null) {
        return this.units.length;
      }
      return 0;
    },
    availableOnly() {
      if (this.filteredUnitsWithoutStatus !== null && this.disableReserve) {
        return this.filteredUnitsWithoutStatus.filter(
          (item) => item.status === "available",
        ).length;
      }
      if (this.filteredUnitsWithoutStatus !== null && !this.disableReserve) {
        return this.filteredUnitsWithoutStatus.filter(
          (item) => item.status === "available",
        ).length;
      }
      return 0;
    },
    isGuaranteedRentals() {
      if (this.filteredUnitsWithoutStatus !== null) {
        return this.filteredUnitsWithoutStatus.filter(
          (item) => item.isGuaranteedRental === true,
        ).length;
      }
      return 0;
    },
  },
  watch: {
    totalUnits: function () {
      this.getUnits();
    },
    orderBy() {
      this.getUnits(
        this.globals[0].isStandNo
          ? this.orderByDBSettingStandNoFirst
          : this.orderByDBSetting,
        this.orderByReverse,
      );
    },
    orderByReverse() {
      this.getUnits(
        this.globals[0].isStandNo
          ? this.orderByDBSettingStandNoFirst
          : this.orderByDBSetting,
        this.orderByReverse,
      );
    },
  },
  async created() {
    this.stopListeningSettings = settingsCollection
      .doc("globalSettings")
      .onSnapshot((doc) => {
        if (doc.data().showAvailableUnits === true) {
          this.filterStatus("available-only");
          this.status = "available-only";
          this.displayAvailableOnly = true;
        }
      });

    let interval = setInterval(() => {
      if (this.units.length > 0) {
        clearInterval(interval);

        let minPriceQuery = toInteger(this.$route.query.minprice);
        let maxPriceQuery = toInteger(this.$route.query.maxprice);
        let aspect = this.$route.query.aspect;
        let view = this.$route.query.view;
        let layout = this.$route.query.layout;
        let floor = this.$route.query.floor;
        let order = this.$route.query.order;
        let status = this.$route.query.status;
        let unitType = this.$route.query.unitType;
        let search = this.$route.query.search;

        if (minPriceQuery && minPriceQuery > 0) {
          this.filterPrice({ price: minPriceQuery, code: "minprice" });
        }

        if (maxPriceQuery) {
          this.filterPrice({ price: maxPriceQuery, code: "maxprice" });
        }

        if (!isNil(aspect)) {
          this.filterAspect(aspect);
        }

        if (!isNil(view)) {
          this.filterView(view);
        }

        if (!isNil(layout)) {
          this.filterLayout(layout);
        }

        if (!isNil(floor)) {
          this.filterFloor(floor);
        }

        if (!isNil(order)) {
          this.filterOrderBy(order);
        }

        if (!isNil(status)) {
          this.filterStatus(status);
        }

        if (!isNil(unitType)) {
          this.filterUnitType(unitType);
        }

        if (!isNil(search)) {
          this.filterName(search);
        }
      }
    }, 2000);
    this.getUnits();

    this.stopListeningSettingsPlan = settingsCollection
      .doc("plan")
      .onSnapshot((doc) => {
        const data = doc.data();
        if (data === undefined) return;

        this.planEnabled = data.enabled;
      });

    this.emitter.on("updateUnitDialogData", (e) => {
      this.updateUnitDialogData(e);
    });
  },
  mounted: function () {
    this.getSplashSessionStorage();
    setInterval(this.updateNow, 1000);
  },
  beforeUnmount() {
    if (this.stopListeningSettings) this.stopListeningSettings();
    if (this.stopListeningSettingsPlan) this.stopListeningSettingsPlan();
    if (this.stopListeningUnits) this.stopListeningUnits();
  },
};
</script>

<style lang="css" scoped>
#backToPriceListBtn {
  background-color: var(--button-background-color);
}

#backToPriceListBtn:hover {
  background-color: var(--button-background-color--hover);
}

#backToPriceListBtn:focus {
  background-color: var(--button-background-color--focus);
}

#shortlistBtn {
  background-color: var(--button-background-color);
}

#shortlistBtn:hover {
  background-color: var(--button-background-color--hover);
}

#shortlistBtn:focus {
  background-color: var(--button-background-color--focus);
}

.borderRadius {
  border-radius: 10px;
}

.iconContainer {
  margin-top: 2px !important;
}

.text-center {
  text-align: center;
}

.centerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
}

.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.filter-sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 30;
  top: 4rem;
  min-height: unset !important;
}

.is-users-online {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 6rem;
  min-height: unset !important;
}

.filter-container-width {
  width: 9.75%;
}

.filter-container-width-one-column-short {
  width: 10.83%;
}

.filter-container-width-two-columns-short {
  width: 12.57%;
}

.filter-container-width-three-columns-short {
  width: 14.66%;
}

.filter-container-width-four-columns-short {
  width: 17.6%;
}

.filter-container-width-five-columns-short {
  width: 22%;
}

.filter-container-price {
  margin: 0.25rem 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
}

.loadMoreButton {
  margin-bottom: 15px;
}

.filter-container {
  margin: 0.25rem 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
}

#container {
  position: flex;
  width: 370px;
  height: 150px;
}

#block {
  background-color: black;
  filter: alpha(opacity=75);
  /* IE */
  -moz-opacity: 0.75;
  /* Mozilla */
  opacity: 0.75;
  /* CSS3 */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

#text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 10%;
  padding-top: 5%;
}

.mobiShow {
  display: none;
}

.mobiMargin {
  margin-left: 4px;
}

.displayAmount {
  padding: 0.5rem 0.25rem;
  font-size: 0.775rem;
  line-height: 1.25rem;
  overflow-wrap: break-word;
}

@media only screen and (max-width: 1258px) {
  .displayAmount {
    padding: 0.5rem 0.25rem;
    font-size: 0.775rem;
    line-height: 1.25rem;
    overflow-wrap: break-word;
  }
}

@media only screen and (max-width: 1210px) {
  .displayAmount {
    padding: 0.5rem 0rem;
    font-size: 0.775rem;
    line-height: 1.25rem;
    overflow-wrap: break-word;
  }
}

@media only screen and (max-width: 1155px) {
  .displayAmount {
    padding: 0.5rem 0rem;
    font-size: 0.775rem;
    line-height: 1.25rem;
    overflow-wrap: break-word;
  }
}

@media only screen and (max-width: 1024px) {
  .displayAmount {
    padding: 0.5rem 0.25rem;
    font-size: 0.775rem;
    line-height: 1.25rem;
    overflow-wrap: break-word;
  }

  .filter-container-price {
    margin: 0 0;
    width: 9.75% !important;
  }

  .filter-container {
    margin: 0 0;
    width: 9.75%;
  }
}

@media only screen and (max-width: 768px) {
  .mobiShow {
    display: contents;
  }

  .mobi {
    display: none;
  }

  .filter-container-price {
    width: 50% !important;
  }

  .filter-container {
    width: 50%;
  }
}

@media (min-width: 640px) {
  .reset-filters {
    width: 12%;
  }
}

@media (max-width: 640px) {
  .mobi {
    display: none;
  }
}

@media (max-width: 425px) {
  #text {
    font-size: 12px;
    padding-left: 6%;
  }

  #container {
    width: 250px;
    height: 120px;
  }

  .filterContainer {
    flex-direction: row;
  }

  .filter-container-price {
    margin: 0.25rem 0;
    width: 100% !important;
  }

  .filter-container {
    margin: 0.25rem 0;
    width: 50%;
  }
}
</style>
