<script setup>
import { ref, onMounted, computed, onBeforeUnmount, watch, toRefs } from "vue";
import { firestoreStore, adjustShade } from "@/utils";
import * as d3 from "d3";

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  fitImageHeight: {
    type: Boolean,
    required: false,
    default() {
      return true;
    },
  },
  initialTransform: {
    type: Object || undefined,
    required: false,
  },
  pins: {
    type: Array,
    required: false,
    default() {
      return [];
    },
  },
  editable: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  disableGestures: {
    type: Boolean,
    required: false,
    default() {
      return false;
    },
  },
  showZoomPercentage: {
    type: Boolean,
    required: false,
    default() {
      return true;
    },
  },
});
const { src, pins } = toRefs(props);

const emit = defineEmits({
  transformed: ({ width, x, y }) =>
    [width, x, y].every((property) => typeof property === "number"),
  addPin: ({ x, y }) =>
    [x, y].every((property) => typeof property === "number"),
  updatePin: ({ index, x, y }) =>
    [index, x, y].every((property) => typeof property === "number"),
  clickPin: ({ event, index, unit }) =>
    event instanceof MouseEvent &&
    typeof index === "number" &&
    (typeof unit === "undefined" || typeof unit === "string"),
});

const {
  state: { theme },
} = firestoreStore();
const backgroundColour = computed(() => {
  if (!theme) return "#ffffff";

  return adjustShade(theme.value.colours.surface, -45);
});

const svg = ref(null);
const k = ref(1);
const scaleUpperLimit = ref(undefined);
const edit = ref(false);
const clickPin = (event, pin) => {
  event.stopPropagation();
  emit("clickPin", {
    event,
    index: pin.index,
    unit: pin?.unit,
  });
};
const dragBehaviour = d3
  .drag()
  .on("start", (_, { node }) => {
    d3.select(node).classed("cursor-grabbing", true);
  })
  .on("drag", ({ x, y }, d) => {
    d.x = x;
    d.y = y;
  })
  .on("end", (_, { index, x, y }) => emit("updatePin", { index, x, y }));
watch(
  pins,
  () => {
    const gElement = d3.select(svg.value).select("g");
    gElement.selectAll(".pin").remove();
    const pinsElements = gElement
      .selectAll(".pin")
      .data(pins.value)
      .enter()
      .append(({ node }) => node)
      .attr("x", ({ x, node }) => x - node.getBBox().width / 2)
      .attr("y", ({ y, node }) => y - node.getBBox().height / 2)
      .classed("pin", true);

    if (!props.editable) {
      pinsElements.classed("cursor-pointer", true).on("click", clickPin);
      return;
    }

    if (!edit.value) return;

    pinsElements.on("click", clickPin);
    pinsElements.call(dragBehaviour).classed("cursor-grab", true);
  },
  { deep: true },
);
watch(edit, () => {
  const imageElement = d3.select(svg.value).select("image");
  const pinsElements = d3.select(svg.value).select("g").selectAll(".pin");
  if (!edit.value) {
    imageElement.on("click", null);
    pinsElements.on(".drag", null).classed("cursor-grab", false);
    pinsElements.on("click", null);
  } else {
    imageElement.on("click", (event) => {
      const [x, y] = d3.pointer(event, imageElement.node());
      emit("addPin", {
        x: x * scaleUpperLimit.value,
        y: y * scaleUpperLimit.value,
      });
    });
    pinsElements.on("click", clickPin);
    pinsElements.call(dragBehaviour).classed("cursor-grab", true);
  }
});

const cursor = computed(() => {
  if (edit.value) return "cursor-copy";

  if (k.value > 1 || (!props.fitImageHeight && zoomPercentage.value < 100))
    return "cursor-move";

  return zoomPercentage.value === 100 ? "cursor-default" : "cursor-zoom-in";
});
const zoomPercentage = computed(
  () => Math.round((k.value / scaleUpperLimit.value) * 100) || 0,
);

let widthImage, heightImage;
const setImageDimensions = async () => {
  [widthImage, heightImage] = await new Promise((resolve) => {
    const image = new Image();
    image.src = src.value;
    image.onload = () => {
      resolve([image.width, image.height]);
    };
  });
};

const div = ref(null);
let widthInitial;
const initializeContainer = () => {
  if (!props.fitImageHeight) {
    div.value.classList.add("h-full");
  }

  const { width: widthDiv, height: heightDiv } =
    div.value.getBoundingClientRect();

  let width;
  let height;
  if (widthImage >= widthDiv) {
    widthInitial = widthDiv;
    width = widthDiv;
    height = widthDiv * (heightImage / widthImage);
  } else {
    widthInitial = widthImage;
    width = widthImage;
    height = heightImage;
  }

  return {
    widthDiv,
    heightDiv,
    width,
    height,
  };
};

const svgElementHeight = ref([]);
let zoomBehaviour;
const initialize = () => {
  const { widthDiv, heightDiv, width, height } = initializeContainer();

  scaleUpperLimit.value = Math.min(widthImage / width, heightImage / height);

  const svgElement = d3.select(svg.value);
  svgElement
    .attr("width", "100%")
    .attr("height", props.fitImageHeight ? height : heightDiv);

  const imageElement = svgElement.append("image");
  imageElement.attr("href", src.value);
  imageElement.attr("width", width);

  const gElement = svgElement.append("g");
  gElement.attr("transform", `scale(${k.value / scaleUpperLimit.value})`);

  const pinsElements = gElement
    .selectAll(".pin")
    .data(pins.value)
    .enter()
    .append(({ node }) => node)
    .attr("x", ({ x, node }) => x - node.getBBox().width / 2)
    .attr("y", ({ y, node }) => y - node.getBBox().height / 2)
    .classed("pin", true);

  if (!props.editable) {
    pinsElements.classed("cursor-pointer", true).on("click", clickPin);
  }

  let translateExtent;
  if (props.fitImageHeight) {
    translateExtent = [
      [-widthDiv, -height],
      [widthDiv + width, height * 2],
    ];
  } else {
    translateExtent = [
      [-widthDiv, -heightDiv],
      [widthDiv + width, heightDiv + height],
    ];
  }
  zoomBehaviour = d3
    .zoom()
    .scaleExtent([0.5, scaleUpperLimit.value])
    .translateExtent(translateExtent)
    .on("zoom", ({ transform }) => {
      if (k.value !== transform.k) {
        k.value = transform.k;
      }

      imageElement.attr("transform", transform);
      gElement.attr(
        "transform",
        `translate(${transform.x}, ${transform.y}) scale(${transform.k / scaleUpperLimit.value})`,
      );

      emit("transformed", {
        width: widthInitial * transform.k,
        x: transform.x,
        y: transform.y,
      });
    });

  svgElement.call(zoomBehaviour.transform, d3.zoomIdentity.scale(k.value));

  if (props.initialTransform) {
    const { x, y } = props.initialTransform;
    const left = props.initialTransform?.left || 0;
    const top = props.initialTransform?.top || 0;
    const scale = props.initialTransform?.width
      ? props.initialTransform.width / width
      : 1;
    svgElement.call(
      zoomBehaviour.transform,
      d3.zoomIdentity.translate(x + left, y + top).scale(scale),
    );
  }

  if (!props.initialTransform && width < widthDiv) {
    svgElement.call(
      zoomBehaviour.transform,
      d3.zoomIdentity.translate((widthDiv - width) / 2, 0),
    );
  }

  if (props.disableGestures) return;

  svgElement.call(zoomBehaviour).on("wheel", (event) => {
    event.preventDefault();
  });
};

const resize = () => {
  const { widthDiv, heightDiv, width, height } = initializeContainer();

  scaleUpperLimit.value = Math.min(widthImage / width, heightImage / height);

  const svgElement = d3.select(svg.value);
  svgElement.attr("height", props.fitImageHeight ? height : heightDiv);

  const imageElement = svgElement.select("image");
  imageElement.attr("width", width);

  let translateExtent;
  if (props.fitImageHeight) {
    translateExtent = [
      [-widthDiv, -height],
      [widthDiv + width, height * 2],
    ];
  } else {
    translateExtent = [
      [-widthDiv, -heightDiv],
      [widthDiv + width, heightDiv + height],
    ];
  }
  zoomBehaviour
    .scaleExtent([0.5, scaleUpperLimit.value])
    .translateExtent(translateExtent);

  if (k.value !== 1) {
    k.value = 1;
  }

  let translateX = 0;
  let translateY = 0;
  if (width < widthDiv) {
    translateX = (widthDiv - width) / 2;
  }
  if (height < heightDiv) {
    translateY = (heightDiv - height) / 2;
  }
  svgElement.call(
    zoomBehaviour.transform,
    d3.zoomIdentity.scale(k.value).translate(translateX, translateY),
  );
};

onMounted(async () => {
  await setImageDimensions();
  initialize();
  window.addEventListener("resize", resize);
});
onBeforeUnmount(() => {
  if (edit.value) {
    const imageElement = d3.select(svg.value).select("image");
    imageElement.on("click", null);
  }
  window.removeEventListener("resize", resize);
});
</script>

<template>
  <div ref="div" class="relative w-full">
    <svg
      ref="svg"
      :class="[cursor, svgElementHeight]"
      :style="`background-color: ${backgroundColour}`"
    ></svg>
    <div
      v-if="showZoomPercentage"
      :class="`absolute right-1 top-1 rounded py-1 px-2 bg-primary-inverse/70 flex items-center text-surface-900 ${cursor}`"
    >
      <i class="pi pi-search mr-2 text-sm"></i>
      <span class="select-none">{{ zoomPercentage }}%</span>
    </div>

    <div
      v-if="editable"
      class="absolute right-1 bottom-1 rounded py-1 px-2 bg-primary-inverse/70 flex flex-col items-end cursor-default"
    >
      <span class="text-surface-900 select-none">Edit Units</span>
      <p-inputswitch v-model="edit"></p-inputswitch>
    </div>
  </div>
</template>
