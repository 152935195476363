<template>
  <div>
    <div v-if="normalPrice">
      <div
        v-if="
          globalSettings.setOneDayOnlyDiscount &&
          unit.isOneDayOnlyDiscount &&
          (unit.status === 'available' ||
            pendingStatuses.includes(unit.status) ||
            unit.status === 'reserved')
        "
      >
        <span
          v-if="!tableStyle"
          :class="!tableStyle ? 'textSizeCustomisableWording' : null"
        >
          <span v-if="!tableStyle">
            {{ unit.priceWording }}
          </span>
        </span>
        <span
          :class="{
            'line-through': user.loggedIn || !user.loggedIn,
            tableWidth: tableStyle,
          }"
          class="block font-bold text-xs"
          v-if="user.loggedIn || (!user.loggedIn && unit.isOneDayOnlyDiscount)"
        >
          {{ prettyCurrency(unit.price) }}
        </span>

        <!-- User signed in but did not answer the buyers profile -->
        <div
          class="inline-block"
          v-if="user.loggedIn || (!user.loggedIn && unit.isOneDayOnlyDiscount)"
        >
          <span
            v-bind:class="{
              'block font-extrabold textSize bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent':
                !tableStyle,
              'block md:mt-2 tableWidth bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent':
                tableStyle,
            }"
          >
            {{ prettyCurrency(unit.price - unit.oneDayOnlyDiscount) }}
          </span>
        </div>
      </div>

      <div v-else-if="globalSettings.allowDiscountNoSurvey">
        <div v-if="unit.status === 'sold' && globalSettings.hidePriceOnSold">
          <span
            class="block font-extrabold uppercase"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Sold
          </span>
        </div>
        <div
          v-else-if="
            unit.status === 'reserved' && globalSettings.hidePriceOnSold
          "
        >
          <span
            class="block font-extrabold uppercase"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Reserved
          </span>
        </div>
        <div
          v-else-if="
            pendingStatuses.includes(unit.status) &&
            globalSettings.hidePriceOnSold
          "
        >
          <span
            class="block font-extrabold uppercase"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Pending
          </span>
        </div>
        <div
          v-else-if="
            unit.status === 'available' && globalSettings.hidePriceOnSold
          "
        >
          <span
            v-if="!tableStyle"
            :class="!tableStyle ? 'textSizeCustomisableWording' : null"
          >
            <span v-if="!tableStyle">
              {{ unit.priceWording }}
            </span>
          </span>
          <span
            :class="{
              'line-through': user.loggedIn || !user.loggedIn,
              tableWidth: tableStyle,
            }"
            class="block font-bold text-xs"
            v-if="
              user.loggedIn ||
              (!user.loggedIn && globalSettings.allowDiscountNoSurvey)
            "
          >
            {{ prettyCurrency(unit.price) }}
          </span>

          <div
            v-if="
              user.loggedIn ||
              (!user.loggedIn && globalSettings.allowDiscountNoSurvey)
            "
          >
            <div v-if="globalSettings.globalDiscountType === 'amount'">
              <span
                class="block font-extrabold text-md bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
                :class="tableStyle ? 'tableWidth' : null"
                v-if="globalSettings.allowDiscountNoSurvey"
              >
                {{
                  prettyCurrency(
                    unit.price - globalSettings.globalDiscountAmount,
                  )
                }}
              </span>
            </div>
            <div v-if="globalSettings.globalDiscountType === 'percentage'">
              <span
                class="block font-extrabold text-md bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
                :class="tableStyle ? 'tableWidth' : null"
                v-if="globalSettings.allowDiscountNoSurvey"
              >
                {{
                  prettyCurrency(
                    unit.price *
                      ((100 - globalSettings.globalDiscountAmount) / 100),
                  )
                }}
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="
            globalSettings.allowDiscountNoSurvey &&
            !globalSettings.hidePriceOnSold
          "
        >
          <span
            v-if="!tableStyle"
            :class="!tableStyle ? 'textSizeCustomisableWording' : null"
          >
            <span v-if="!tableStyle">
              {{ unit.priceWording }}
            </span>
          </span>
          <span
            :class="{
              'line-through': user.loggedIn || !user.loggedIn,
              tableWidth: tableStyle,
            }"
            class="block font-bold text-xs"
            v-if="
              user.loggedIn ||
              (!user.loggedIn && globalSettings.allowDiscountNoSurvey)
            "
          >
            {{ prettyCurrency(unit.price) }}
          </span>

          <div
            v-if="
              user.loggedIn ||
              (!user.loggedIn && globalSettings.allowDiscountNoSurvey)
            "
          >
            <div v-if="globalSettings.globalDiscountType === 'amount'">
              <span
                class="block font-extrabold text-md bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
                :class="tableStyle ? 'tableWidth' : null"
                v-if="globalSettings.allowDiscountNoSurvey"
              >
                {{
                  prettyCurrency(
                    unit.price - globalSettings.globalDiscountAmount,
                  )
                }}
              </span>
            </div>
            <div v-if="globalSettings.globalDiscountType === 'percentage'">
              <span
                class="block font-extrabold text-md bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
                :class="tableStyle ? 'tableWidth' : null"
                v-if="globalSettings.allowDiscountNoSurvey"
              >
                {{
                  prettyCurrency(
                    unit.price *
                      ((100 - globalSettings.globalDiscountAmount) / 100),
                  )
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="unit.status === 'sold' && globalSettings.hidePriceOnSold">
          <span
            class="block font-extrabold uppercase"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Sold
          </span>
        </div>
        <div
          v-else-if="
            unit.status === 'reserved' && globalSettings.hidePriceOnSold
          "
        >
          <span
            class="block font-extrabold uppercase"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Reserved
          </span>
        </div>
        <div
          v-else-if="
            pendingStatuses.includes(unit.status) &&
            globalSettings.hidePriceOnSold
          "
        >
          <span
            class="block font-extrabold uppercase"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Pending
          </span>
        </div>
        <!-- If allowDiscount -->
        <div v-else-if="globalSettings.allowGlobalDiscount">
          <span
            v-if="!tableStyle"
            :class="!tableStyle ? 'textSizeCustomisableWording' : null"
          >
            <span v-if="!tableStyle">
              {{ unit.priceWording }}
            </span>
          </span>
          <span
            :class="{
              'line-through': user.loggedIn || !user.loggedIn,
              tableWidth: tableStyle,
            }"
            class="block font-bold text-xs"
            v-if="user.loggedIn && user.profile.answeredDiscountSurvey"
          >
            {{ prettyCurrency(unit.price) }}
          </span>

          <!-- User signed in but did not answer the buyers profile -->
          <div
            class="inline-block"
            v-if="
              !user.loggedIn ||
              (user.loggedIn && !user.profile.answeredDiscountSurvey)
            "
          >
            <span
              class="block font-extrabold bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
              :class="
                !tableStyle
                  ? 'textSize'
                  : myUnitModal
                    ? 'block md:mt-2'
                    : 'block md:mt-2 tableWidth'
              "
            >
              {{ prettyCurrency(unit.price) }}
            </span>
            <router-link
              :to="{ name: !user.loggedIn ? 'login' : 'survey' }"
              class="flex flex-row px-2 py-1 text-xs text-white uppercase bg-green-500 rounded"
              :class="
                globalSettings.globalDiscountType === 'percentage'
                  ? !tableStyle
                    ? 'newDiscountBtnPercentage'
                    : myUnitModal
                      ? 'newDiscountBtnModalPercentage'
                      : 'newDiscountBtnTablePercentage'
                  : !tableStyle
                    ? 'newDiscountBtn'
                    : myUnitModal
                      ? 'newDiscountBtnModal'
                      : 'newDiscountBtnTable'
              "
              v-if="show"
            >
              <span v-if="globalSettings.globalDiscountType === 'amount'">
                <!--                  <UnlockIcon class="text-xs"/>-->
                <span class="flexContainer">
                  <span class="textContainer"
                    >Unlock &nbsp;<b>{{
                      prettyCurrency(globalSettings.globalDiscountAmount)
                    }}</b></span
                  >
                  <span class="textContainer"> discount</span>
                </span>
              </span>

              <span v-if="globalSettings.globalDiscountType === 'percentage'">
                <!--                  <UnlockIcon class="text-xs"/>-->
                <span class="flexContainer">
                  <span class="textContainer"
                    >Unlock &nbsp;<b
                      >{{ globalSettings.globalDiscountAmount }}%</b
                    ></span
                  >
                  <span class="textContainer"> discount</span>
                </span>
              </span>
            </router-link>
          </div>

          <div v-if="user.loggedIn && user.profile.answeredDiscountSurvey">
            <div v-if="globalSettings.globalDiscountType === 'amount'">
              <span
                class="block font-extrabold bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
                :class="tableStyle ? 'tableWidth' : null"
                v-if="globalSettings.allowGlobalDiscount"
              >
                {{
                  prettyCurrency(
                    unit.price - globalSettings.globalDiscountAmount,
                  )
                }}
              </span>
            </div>
            <div v-if="globalSettings.globalDiscountType === 'percentage'">
              <span
                class="block font-extrabold bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
                :class="tableStyle ? 'tableWidth' : null"
                v-if="globalSettings.allowGlobalDiscount"
              >
                {{
                  prettyCurrency(
                    unit.price *
                      ((100 - globalSettings.globalDiscountAmount) / 100),
                  )
                }}
              </span>
            </div>
          </div>
        </div>

        <!-- If allowDiscount = false -->
        <div v-else>
          <span
            v-if="!tableStyle"
            :class="!tableStyle ? 'textSizeCustomisableWording' : null"
          >
            <span v-if="!tableStyle">
              {{ unit.priceWording }}
            </span>
          </span>
          <span
            class="block font-extrabold bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2 tableWidth'"
          >
            {{ prettyCurrency(unit.price) }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="bigPrice">
      <div
        v-if="
          globalSettings.setOneDayOnlyDiscount &&
          unit.isOneDayOnlyDiscount &&
          (unit.status === 'available' ||
            pendingStatuses.includes(unit.status) ||
            unit.status === 'reserved')
        "
      >
        <span
          v-if="!tableStyle"
          :class="!tableStyle ? 'textSizeCustomisableWording' : null"
        >
          <span v-if="!tableStyle">
            {{ unit.priceWording }}
          </span>
        </span>
        <span
          :class="{
            'line-through': user.loggedIn || !user.loggedIn,
            tableWidth: tableStyle,
          }"
          class="block font-bold text-xs"
          v-if="user.loggedIn || (!user.loggedIn && unit.isOneDayOnlyDiscount)"
        >
          {{ prettyCurrency(unit.price) }}
        </span>

        <!-- User signed in but did not answer the buyers profile -->
        <div
          class="inline-block"
          v-if="user.loggedIn || (!user.loggedIn && unit.isOneDayOnlyDiscount)"
        >
          <span
            class="block font-extrabold bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
            :class="tableStyle ? 'textSize tableWidth' : 'block md:mt-2'"
          >
            {{ prettyCurrency(unit.price - unit.oneDayOnlyDiscount) }}
          </span>
        </div>
      </div>

      <div v-else-if="globalSettings.allowDiscountNoSurvey">
        <div v-if="unit.status === 'sold' && globalSettings.hidePriceOnSold">
          <span
            class="block font-extrabold uppercase"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Sold
          </span>
        </div>
        <div
          v-else-if="
            unit.status === 'reserved' && globalSettings.hidePriceOnSold
          "
        >
          <span
            class="block font-extrabold uppercase"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Reserved
          </span>
        </div>
        <div
          v-else-if="
            pendingStatuses.includes(unit.status) &&
            globalSettings.hidePriceOnSold
          "
        >
          <span
            class="block font-extrabold uppercase"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Pending
          </span>
        </div>
        <div
          v-if="unit.status === 'available' && globalSettings.hidePriceOnSold"
        >
          <span
            v-if="!tableStyle"
            :class="!tableStyle ? 'textSizeCustomisableWording' : null"
          >
            <span v-if="!tableStyle">
              {{ unit.priceWording }}
            </span>
          </span>
          <span
            :class="{
              'line-through': user.loggedIn || !user.loggedIn,
              tableWidth: tableStyle,
            }"
            class="block priceBlackCss font-bold text-xs"
            v-if="
              user.loggedIn ||
              (!user.loggedIn && globalSettings.allowDiscountNoSurvey)
            "
          >
            {{ prettyCurrency(unit.price) }}
          </span>

          <div
            v-if="
              user.loggedIn ||
              (!user.loggedIn && globalSettings.allowDiscountNoSurvey)
            "
          >
            <div v-if="globalSettings.globalDiscountType === 'amount'">
              <span
                class="block priceGoldCss font-extrabold text-md bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
                :class="tableStyle ? 'tableWidth' : null"
                v-if="globalSettings.allowDiscountNoSurvey"
              >
                {{
                  prettyCurrency(
                    unit.price - globalSettings.globalDiscountAmount,
                  )
                }}
              </span>
            </div>
            <div v-if="globalSettings.globalDiscountType === 'percentage'">
              <span
                class="block priceGoldCss font-extrabold text-md bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
                :class="tableStyle ? 'tableWidth' : null"
                v-if="globalSettings.allowDiscountNoSurvey"
              >
                {{
                  prettyCurrency(
                    unit.price *
                      ((100 - globalSettings.globalDiscountAmount) / 100),
                  )
                }}
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="
            globalSettings.allowDiscountNoSurvey &&
            !globalSettings.hidePriceOnSold
          "
        >
          <span
            v-if="!tableStyle"
            :class="!tableStyle ? 'textSizeCustomisableWording' : null"
          >
            <span v-if="!tableStyle">
              {{ unit.priceWording }}
            </span>
          </span>
          <span
            :class="{
              'line-through': user.loggedIn || !user.loggedIn,
              tableWidth: tableStyle,
            }"
            class="block font-bold text-xs"
            v-if="
              user.loggedIn ||
              (!user.loggedIn && globalSettings.allowDiscountNoSurvey)
            "
          >
            {{ prettyCurrency(unit.price) }}
          </span>

          <div
            v-if="
              user.loggedIn ||
              (!user.loggedIn && globalSettings.allowDiscountNoSurvey)
            "
          >
            <div v-if="globalSettings.globalDiscountType === 'amount'">
              <span
                class="block font-extrabold text-md bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
                :class="tableStyle ? 'tableWidth' : null"
                v-if="globalSettings.allowDiscountNoSurvey"
              >
                {{
                  prettyCurrency(
                    unit.price - globalSettings.globalDiscountAmount,
                  )
                }}
              </span>
            </div>
            <div v-if="globalSettings.globalDiscountType === 'percentage'">
              <span
                class="block font-extrabold text-md bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
                :class="tableStyle ? 'tableWidth' : null"
                v-if="globalSettings.allowDiscountNoSurvey"
              >
                {{
                  prettyCurrency(
                    unit.price *
                      ((100 - globalSettings.globalDiscountAmount) / 100),
                  )
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="unit.status === 'sold' && globalSettings.hidePriceOnSold">
          <span
            class="block font-extrabold uppercase"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Sold
          </span>
        </div>
        <div
          v-else-if="
            unit.status === 'reserved' && globalSettings.hidePriceOnSold
          "
        >
          <span
            class="block font-extrabold uppercase"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Reserved
          </span>
        </div>
        <div
          v-else-if="
            pendingStatuses.includes(unit.status) &&
            globalSettings.hidePriceOnSold
          "
        >
          <span
            class="block font-extrabold uppercase"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Pending
          </span>
        </div>
        <!-- If allowDiscount -->
        <div v-else-if="globalSettings.allowGlobalDiscount">
          <span
            v-if="!tableStyle"
            :class="!tableStyle ? 'textSizeCustomisableWording' : null"
          >
            <span v-if="!tableStyle">
              {{ unit.priceWording }}
            </span>
          </span>
          <span
            :class="{
              'line-through': user.loggedIn,
              tableWidth: tableStyle,
            }"
            class="block priceBlackCss font-bold text-xs"
            v-if="user.loggedIn && user.profile.answeredDiscountSurvey"
          >
            {{ prettyCurrency(unit.price) }}
          </span>

          <!-- User signed in but did not answer the buyers profile -->
          <div
            class="inline-block"
            v-if="
              !user.loggedIn ||
              (user.loggedIn && !user.profile.answeredDiscountSurvey)
            "
          >
            <span
              class="block font-extrabold bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
              :class="
                !tableStyle
                  ? 'textSize'
                  : myUnitModal
                    ? 'block md:mt-2'
                    : 'block md:mt-2 tableWidth'
              "
            >
              {{ prettyCurrency(unit.price) }}
            </span>
            <router-link
              :to="{ name: !user.loggedIn ? 'login' : 'survey' }"
              class="flex flex-row px-2 py-1 text-xs text-white uppercase bg-green-500 rounded"
              :class="
                globalSettings.globalDiscountType === 'percentage'
                  ? !tableStyle
                    ? 'newDiscountBtnPercentage'
                    : myUnitModal
                      ? 'newDiscountBtnModalPercentage'
                      : 'newDiscountBtnTablePercentage'
                  : !tableStyle
                    ? 'newDiscountBtn'
                    : myUnitModal
                      ? 'newDiscountBtnModal'
                      : 'newDiscountBtnTable'
              "
              v-if="show"
            >
              <span v-if="globalSettings.globalDiscountType === 'amount'">
                <!--                  <UnlockIcon class="text-xs"/>-->
                <span class="ml-1 flexContainer">
                  <span class="textContainer"
                    >Unlock &nbsp;<b>{{
                      prettyCurrency(globalSettings.globalDiscountAmount)
                    }}</b></span
                  >
                  <span class="textContainer"> discount</span>
                </span>
              </span>

              <span v-if="globalSettings.globalDiscountType === 'percentage'">
                <!--                  <UnlockIcon class="text-xs"/>-->
                <span class="ml-1 flexContainer">
                  <span class="textContainer"
                    >Unlock &nbsp;<b
                      >{{ globalSettings.globalDiscountAmount }}%</b
                    ></span
                  >
                  <span class="textContainer"> discount</span>
                </span>
              </span>
            </router-link>
          </div>

          <div v-if="user.loggedIn && user.profile.answeredDiscountSurvey">
            <div v-if="globalSettings.globalDiscountType === 'amount'">
              <span
                class="block priceGoldCss font-extrabold text-md bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
                :class="tableStyle ? 'tableWidth' : null"
                v-if="globalSettings.allowGlobalDiscount"
              >
                {{
                  prettyCurrency(
                    unit.price - globalSettings.globalDiscountAmount,
                  )
                }}
              </span>
            </div>
            <div v-if="globalSettings.globalDiscountType === 'percentage'">
              <span
                class="block priceGoldCss font-extrabold text-md bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
                :class="tableStyle ? 'tableWidth' : null"
                v-if="globalSettings.allowGlobalDiscount"
              >
                {{
                  prettyCurrency(
                    unit.price *
                      ((100 - globalSettings.globalDiscountAmount) / 100),
                  )
                }}
              </span>
            </div>
          </div>
        </div>

        <!-- If allowDiscount = false -->
        <div v-else>
          <span
            v-if="!tableStyle"
            :class="!tableStyle ? 'textSizeCustomisableWording' : null"
          >
            <span v-if="!tableStyle">
              {{ unit.priceWording }}
            </span>
          </span>
          <span
            class="block font-extrabold bg-gradient-to-br from-secondary-300 to-secondary-700 bg-clip-text text-transparent"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2 tableWidth'"
          >
            {{ prettyCurrency(unit.price) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { firestoreStore } from "@/utils/index.js";

export default {
  name: "UnitPrice",
  props: {
    unit: {
      default: null,
    },
    // TODO: add prop that will allow different styling based on where this component is placed and not specific style props
    tableStyle: {
      type: Boolean,
      default: false,
    },
    myUnitModal: {
      type: Boolean,
      default: false,
    },
    settings: {
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    bigPrice: {
      type: Boolean,
      default: true,
    },
    normalPrice: {
      type: Boolean,
      default: true,
    },
    showWording: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    globals: [],
    now: new Date(),
    pendingStatuses: ["pending", "pendingUnitDetails", "pendingPayment"],
    globalSettings: null,
  }),
  created() {
    const {
      state: { globalSettings },
    } = firestoreStore();
    this.globalSettings = globalSettings;
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>

<style>
.textContainer {
  -webkit-text-wrap: nowrap;
  text-wrap: nowrap;
}

.flexContainer {
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.tableWidth {
  width: auto;
  min-width: 91px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.newDiscountBtnTable {
  min-width: 140px;
  display: flex;
  text-align: center;
}

.newDiscountBtnModal {
  min-width: 140px;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.newDiscountBtn {
  min-width: 140px;
  display: flex;
  text-align: center;
}

.newDiscountBtnTablePercentage {
  min-width: 118px;
  display: flex;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.newDiscountBtnModalPercentage {
  min-width: 118px;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.newDiscountBtnPercentage {
  min-width: 118px;
  display: flex;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.textSizeCustomisableWording {
  display: block;
  font-weight: 800;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
}

.textSize2 {
  font-size: 14px;
  line-height: 1.75rem;
}

.textSize {
  font-size: 1.2rem;
  line-height: 1.75rem;
}

span.material-design-icon {
  margin-top: 5px !important;
}

.priceGoldCss {
  font-size: 28px !important;
  font-weight: 600;
}

.priceBlackCss {
  font-size: 18px !important;
  font-weight: 550;
}

@media only screen and (max-width: 425px) {
  svg.material-design-icon__svg {
    padding: 5px 0 0 0 !important;
  }

  .newDiscountBtn {
    min-width: 100%;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    font-size: 0.7rem !important;
  }
  .newDiscountBtnPercentage {
    min-width: 100%;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    font-size: 0.7rem !important;
  }
}

@media only screen and (max-width: 375px) {
  .textSize {
    font-size: 0.8rem;
    line-height: 1.75rem;
  }

  .textSizeCustomisableWording {
    display: block;
    font-weight: 800;
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
    font-size: 0.8rem;
    line-height: 1.75rem;
  }
}
</style>
