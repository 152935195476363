<template>
  <div :class="computedClasses">
    <p
      v-if="this.statuses.includes(unit.status)"
      class="status-label text-3xl text-white"
    >
      {{ this.pendingStatuses.includes(unit.status) ? "pending" : unit.status }}
    </p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PendingOrPendingOrReserveOrSoldLayout",
  props: {
    unit: {
      default: {},
    },
  },
  data: () => ({
    pendingStatuses: ["pending", "pendingUnitDetails", "pendingPayment"],
    statuses: [
      "pending",
      "pendingUnitDetails",
      "pendingPayment",
      "reserved",
      "sold",
    ],
  }),
  computed: {
    computedClasses() {
      const backgroundColorClass = this.pendingStatuses.includes(
        this.unit.status,
      )
        ? "bg-surface-800/40"
        : this.unit.status === "reserved"
          ? "bg-surface-800/60"
          : this.unit.status === "sold"
            ? "bg-surface-800/80"
            : "";
      const zIndexClass = this.statuses.includes(this.unit.status)
        ? "z-20"
        : "z-0";

      const overlay = this.statuses.includes(this.unit.status) ? "overlay" : "";

      return {
        [backgroundColorClass]: true,
        [zIndexClass]: true,
        [overlay]: true,
      };
    },
  },
};
</script>

<style lang="css" scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.status-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 25%;
}
</style>
