<template>
  <div id="app">
    <NavBarUsersOnline />
    <NavBar
      :msTillLaunchTime="msTillLaunchTime"
      :launchDateTime="launchDateTime"
    />
    <MainNavBarContactModal :msTillLaunchTime="msTillLaunchTime" />
    <div v-if="globalSettings.chatApp === 'none'">
      <div></div>
    </div>
    <div v-else-if="globalSettings.chatApp === 'socialChatApp'">
      <SocialChat />
    </div>
    <div v-else-if="globalSettings.chatApp === 'whatsAppUrl'">
      <a :href="globalSettings.urlWhatsApp" class="float" target="_blank">
        <span class="pi pi-whatsapp my-float"></span>
      </a>
    </div>
    <router-view :msTillLaunchTime="msTillLaunchTime" />
  </div>
</template>

<script>
import NavBar from "@/components/app/NavBar.vue";
import MainNavBarContactModal from "@/components/app/MainNavBarContactModal.vue";
import SocialChat from "@/components/app/SocialChat.vue";
import NavBarUsersOnline from "@/components/app/NavBarUsersOnline.vue";
import firebase from "firebase/compat/app";

import { useMaxReservableUnitHandler } from "@/composables";
import { mapState } from "pinia";
import { useUserStore } from "@/state/index.js";
import { firestoreStore } from "@/utils/index.js";
import { httpsCallable } from "firebase/functions";
import { functionsInstance } from "@/firebase.js";

export default {
  name: "App",
  setup() {
    useMaxReservableUnitHandler();
  },
  props: {
    launchDateTime: {
      type: Date,
    },
  },
  data() {
    return {
      intervalServerTimer: null,
      msTillLaunchTime: 0,
      globalSettings: null,
    };
  },
  components: {
    NavBarUsersOnline,
    NavBar,
    MainNavBarContactModal,
    SocialChat,
  },
  created() {
    const {
      state: { globalSettings },
    } = firestoreStore();
    this.globalSettings = globalSettings;
    this.getServerTimer();

    // Performing this call so as to warm up the cloud function used for reservation check
    const getIsLaunchDate = httpsCallable(
      functionsInstance,
      "settings-isAfterLaunchDate",
    );
    getIsLaunchDate();
  },
  mounted() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  unmounted() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
    );
  },
  computed: {
    ...mapState(useUserStore, ["uid"]),
  },
  watch: {
    launchDateTime: {
      deep: true,
      handler(newVal) {
        if (newVal) this.getServerTimer();
      },
    },
  },
  methods: {
    handleVisibilityChange() {
      if (!document.hidden) {
        this.getServerTimer();
      }
    },
    getServerTimer: function () {
      const launchDateTime = this.launchDateTime;

      // fast exiting this function if the launch date time has passed
      if (!launchDateTime) {
        return;
      }
      if (launchDateTime < new Date()) {
        this.msTillLaunchTime = -1;
        return;
      }

      const db = firebase.firestore();
      const countdownRef = db.collection("countdown").doc(this.uid);

      countdownRef
        .set(
          {
            startAt: firebase.firestore.FieldValue.serverTimestamp(),
            launchDateTimeMs: launchDateTime.getTime(),
          },
          { merge: true },
        )
        .then(() => {
          countdownRef.get().then((snapshot) => {
            const data = snapshot.data();
            const launchDateTimeMs = data.launchDateTimeMs;
            const startAt = data.startAt ? data.startAt.toDate() : new Date();

            //Get the difference between local machine and server time
            let serverTimeOffset = new Date().getTime() - startAt.getTime();

            if (this.intervalServerTimer === null) {
              this.intervalServerTimer = setInterval(() => {
                // Calculate time left
                const timeLeft =
                  parseInt(launchDateTimeMs) -
                  (new Date().getTime() - serverTimeOffset);

                // Update msTillLaunchTime
                if (timeLeft < 0) {
                  clearInterval(this.intervalServerTimer);
                  this.msTillLaunchTime = -1;
                } else {
                  this.msTillLaunchTime = timeLeft;
                }
              }, 100);
            }
          });
        })
        .catch((error) => {
          console.error("Error setting countdown:", error);
        });
    },
  },
};
</script>
<style>
::before,
::after {
  border-width: 0;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}
</style>
