<script setup>
import { toRefs, ref, watch } from "vue";
import { useCollection } from "vuefire";
import { plansRef } from "@/firebase";
import { useUnitPinConfiguration, useAbbreviatedPrice } from "@/composables";
import { unitPin } from "@/utils";
import Plan from "./Plan.vue";

const { data: plans } = useCollection(plansRef);

const props = defineProps({
  units: {
    type: Array,
    required: true,
  },
  filteredUnitsIds: {
    type: Array,
    required: true,
  },
});
const { units, filteredUnitsIds } = toRefs(props);

const { unitPinConfigurationAvailable, unitPinConfigurationUnavailable } =
  useUnitPinConfiguration();

const interactiveImages = ref([]);
watch(
  [
    plans,
    units,
    filteredUnitsIds,
    unitPinConfigurationAvailable,
    unitPinConfigurationUnavailable,
  ],
  () => {
    if (
      units.value.length === 0 ||
      plans.value.length === 0 ||
      unitPinConfigurationAvailable.value === undefined ||
      unitPinConfigurationUnavailable.value === undefined
    )
      return;

    interactiveImages.value = [
      ...plans.value.map((plan) => {
        let pins = [];
        if (plan.pins?.length > 0) {
          plan.pins.forEach(({ xCoord: x, yCoord: y, unit }, index) => {
            const assignedUnit = units.value.find(({ id }) => id === unit);
            if (assignedUnit === undefined) return;

            const { id, name, status, price } = assignedUnit;
            let unitPinConfig = {
              name,
              scaleOnHover: 1.4,
              classList: !filteredUnitsIds.value.includes(id)
                ? "opacity-50"
                : undefined,
            };

            if (status === "available") {
              unitPinConfig = {
                ...unitPinConfig,
                ...unitPinConfigurationAvailable.value,
                price: useAbbreviatedPrice(price),
              };
            } else {
              unitPinConfig = {
                ...unitPinConfig,
                ...unitPinConfigurationUnavailable.value,
                price: "SOLD",
              };
            }

            pins.push({
              index,
              unit,
              x,
              y,
              node: unitPin(unitPinConfig),
            });
          });
        }

        return {
          plan,
          pins,
        };
      }),
    ].sort((a, b) => a.plan.order - b.plan.order);
  },
  { immediate: true, deep: true },
);

const emit = defineEmits({
  clickPin: ({ unit }) => typeof unit === "string",
});
const clickPin = ({ unit }) => {
  emit("clickPin", { unit });
};
</script>
<template>
  <div class="sm:px-7 px-2 pb-7 flex flex-col items-center gap-6">
    <div
      v-for="{ plan, pins } in interactiveImages"
      v-bind:key="plan.id"
      class="w-full flex flex-col items-center"
    >
      <h1 class="mb-1">{{ plan.name }}</h1>
      <p v-if="plan.description" class="mb-3 max-w-[1024px] justify-center">
        {{ plan.description }}
      </p>
      <Plan :src="plan.image.full" :pins="pins" @clickPin="clickPin" />
    </div>
  </div>
</template>

<style scoped>
.justify-center {
  text-align: justify;
  text-align-last: center;
}
</style>
